import api from "../httpClient.js";

export async function fetchCidades() {
  const response = await api.get("/cidades");
  return response.data.cidades;
}

export async function fetchCidade(id) {
  const response = await api.get(`/cidades/${id}`);
  return response.data.cidades;
}

export async function putCidade(id, cidade) {
  let body = {};
  for (let key in cidade) {
    body[key] = cidade[key];
  }
  return api.put(`/cidades/${id}`, body);
}

export async function postCidade(cidade) {
  let body = {};
  for (let key in cidade) {
    body[key] = cidade[key];
  }
  return api.post("/cidades/add", body);
}
