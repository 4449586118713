<template>
  <v-container v-if="vueDepositos">
    <v-row>
      <v-col cols="12">
        <PageToolbar title="Depositos" icon="store" dark />
      </v-col>

      <v-col cols="12" v-if="depositos">
        <v-card>
          <v-card-title class="lightgray pa-3">
            <v-text-field
              style="max-width: 400px;"
              v-model="search"
              append-icon="search"
              label="Buscar"
              clearable
              outlined
              solo
              flat
              dense
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn
              color="buttons"
              depressed
              dark
              @click="adicionarDeposito"
              v-if="vueAddDeposito"
            >
              Deposito
              <v-icon right>add</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="depositos"
              :search="search"
              :items-per-page="-1"
              :sort-by="['id']"
              class="data-tables "
              :class="{ 'data-tables__row-click': vueEditDeposito }"
              @click:row="openDeposito"
            >
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogDeposito" width="100%" max-width="400px">
      <v-card :loading="loading" :disabled="loading">
        <v-card-title class="subtitle-1 lightgray">
          <template v-if="dialogModeadd">Adicionar Deposito</template>
          <template v-else>{{ deposito.descricao }}</template>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogDeposito = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <!-- Descrição -->
          <v-text-field
            v-model="deposito.descricao"
            label="Descrição"
            class="mt-4"
            filled
            hide-details
            dense
          ></v-text-field>

          <!-- Cidade -->
          <v-select
            v-model="deposito.cidade_id"
            :items="cidades"
            :loading="loadingCidades"
            class="mt-4"
            clearable
            item-text="nome"
            item-value="id"
            dense
            filled
            hide-details
            label="Cidade"
          ></v-select>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="dialogModeadd"
            color="toolbar"
            depressed
            dark
            small
            :disabled="disableAdd"
            @click="postDeposito"
          >
            Criar
          </v-btn>
          <v-btn
            v-else
            color="toolbar"
            depressed
            dark
            small
            :disabled="disableAdd"
            @click="putDeposito"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
  <NaoAutorizado v-else />
</template>

<script>
import {
  fetchDepositos,
  Api_postDeposito,
  Api_putDeposito,
} from "@/services/api/produtos.api.js";
import { fetchCidades } from "@/services/api/cidades.api.js";
import { mapGetters } from "vuex";

export default {
  name: "Depositos",
  props: {},
  data() {
    return {
      search: "",
      loading: false,
      depositos: null,
      deposito: {},
      dialogModeadd: false,
      dialogDeposito: false,
      headers: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Descricao",
          value: "descricao",
        },
        {
          text: "Cidade",
          value: "cidade",
        },
        {
          text: "Data",
          value: "created_at",
        },
      ],
      cidades: null,
      loadingCidades: false,
    };
  },
  computed: {
    disableAdd() {
      if (this.deposito.descricao && this.deposito.cidade_id) {
        return false;
      } else {
        return true;
      }
    },
    ...mapGetters("Componentes", ["getAccess"]),
    vueDepositos() {
      return this.getAccess("vueDepositos", "menu");
    },
    vueEditDeposito() {
      return this.getAccess("vueDeposito", "editar");
    },
    vueAddDeposito() {
      return this.getAccess("vueDeposito", "adicionar");
    },
  },
  methods: {
    getDepositos() {
      this.$Progress.start();
      fetchDepositos()
        .then((response) => {
          this.depositos = response;
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
        })
        .finally(() => {
          this.$Progress.finish();
        });
    },
    getCidades() {
      this.loadingCidades = true;
      fetchCidades()
        .then((response) => {
          this.cidades = response;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingCidades = false;
        });
    },
    adicionarDeposito() {
      this.deposito = {};
      this.dialogModeadd = true;
      this.dialogDeposito = true;
    },
    openDeposito(item) {
      if (this.vueEditDeposito) {
        this.deposito = item;
        this.dialogModeadd = false;
        this.dialogDeposito = true;
      }
    },
    postDeposito() {
      this.loading = true;
      this.$Progress.start();
      Api_postDeposito(this.deposito)
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success("Deposito criado com sucesso!");
            this.deposito = {};
            this.getDepositos();
            this.dialogDeposito = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
        })
        .finally(() => {
          this.loading = false;
          this.$Progress.finish();
        });
    },
    putDeposito() {
      this.loading = true;
      this.$Progress.start();
      Api_putDeposito(this.deposito.id, this.deposito)
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success("Deposito atualizado com sucesso!");
            this.dialogDeposito = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
        })
        .finally(() => {
          this.loading = false;
          this.$Progress.finish();
        });
    },
  },
  created() {
    if (this.vueDepositos) {
      this.getDepositos();
      this.getCidades();
    }
  },
  mounted() {},
};
</script>

<style lang="scss" scoped></style>
