import api from "../httpClient.js";

// Produtos
export async function getAllProdutos(filtro) {
  const response = await api.get(`/produtos${filtro}`);
  return response.data.produto;
}
export async function getOneProdutos(id) {
  const response = await api.get(`/produtos/${id}`);
  return response.data;
}
export async function putProdutos(id, produtos) {
  let body = {};
  for (let key in produtos) {
    body[key] = produtos[key];
  }
  return api.put(`/produtos/${id}`, body);
}
export async function postProdutos(produtos) {
  let body = {};
  for (let key in produtos) {
    body[key] = produtos[key];
  }
  return api.post("produtos/add", body);
}

// Produtos Classificacoes
export async function getAllProClass() {
  const response = await api.get("/produtosclassificacoes");
  return response.data.produtosclassificacoes;
}

export async function putProClass(id, classificacao) {
  let body = {};
  for (let key in classificacao) {
    body[key] = classificacao[key];
  }
  return api.put(`/produtosclassificacoes/${id}`, body);
}

export async function postProClass(classificacao) {
  let body = {};
  for (let key in classificacao) {
    body[key] = classificacao[key];
  }
  return api.post("produtosclassificacoes/add", body);
}

// Produtos Movimentações
export async function getProdutoMovimentacoes(filtros) {
  const response = await api.get(`/produtosmovimentos${filtros}`);
  return response.data.produtosmovimentos;
}

export async function putProdutoMovimentacoes(id, movimentacao) {
  let body = {};
  for (let key in movimentacao) {
    body[key] = movimentacao[key];
  }
  return api.put(`produtosmovimentos/${id}`, body);
}

export async function postProdutoMovimentacoes(movimentacao) {
  let body = {};
  for (let key in movimentacao) {
    body[key] = movimentacao[key];
  }
  return api.post("produtosmovimentos/add", body);
}

// Depositos
// Produtos Movimentações
export async function fetchDepositos(filtros) {
  let response = "";
  if (filtros) {
    response = await api.get(`/produtosdepositos${filtros}`);
  } else {
    response = await api.get(`/produtosdepositos`);
  }
  return response.data.produtosdepositos;
}

export async function Api_postDeposito(deposito) {
  let body = {};
  for (let key in deposito) {
    body[key] = deposito[key];
  }
  return api.post("produtosdepositos/add", body);
}

export async function Api_putDeposito(id, deposito) {
  let body = {};
  for (let key in deposito) {
    if (key != "cidade") {
      body[key] = deposito[key];
    }
  }
  return api.put(`/produtosdepositos/${id}`, body);
}

// Upload Image
export async function uploadImage(fd) {
  const response = await api.post("/produtos/uploadImage", fd);
  return response;
}
